<template>
  <div>
    
    <v-skeleton-loader :loading="firstLoad" :transition="transition" :tile="true" type="table">
      
      <v-data-table
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="totalEntities"
        :loading="loading"
        :search="search"
        item-key="id"
        dense
      >

        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{new Date(item.createdAt).toLocaleString()}}</span>
        </template>
        <template v-slot:[`item.languageId`]="{ item }">

          <v-chip
            class="ma-2"            
            label
            small
          >
          
          
            <country-flag :country='getFlagFromLangCode(item.languageId)' size='small'/>
            <span class="ml-2">{{item.languageId}}</span>
          </v-chip>


         
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          <span>{{new Date(item.updatedAt).toLocaleString()}}</span>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{schema.name}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="getDataFromApiDebounced"
              @input="getDataFromApiDebounced"
            ></v-text-field>
            
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>You can use % as a wildcard</span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              @click="newItem"
            >
              <v-icon left>mdi-plus-circle-outline</v-icon>
            {{ $vuetify.lang.t('$vuetify.addNew') }}</v-btn>

            <v-dialog
              v-model="dialog"              
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              :retain-focus="false"
            >
              
              <v-card flat>
      <v-toolbar flat dark color="primary">
        <v-container fluid>
          <v-row align="center" justify="space-between">
            <v-col>
              <v-toolbar-title>{{ isInsert ? $vuetify.lang.t('$vuetify.newItem') : $vuetify.lang.t('$vuetify.editItem')}}</v-toolbar-title>
            </v-col>
            <v-col class="text-right">
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>

      <v-container>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit/*, invalid,errors*/ }">
          <form @submit.prevent="handleSubmit(save)" v-if="form_loaded" novalidate>
            <v-card-actions>
              <v-btn text color="secondary" @click="close">
                <v-icon left>mdi-backspace-outline</v-icon>
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" type="submit">
                <v-icon left>mdi-content-save</v-icon>
                {{ $vuetify.lang.t('$vuetify.save') }}
              </v-btn>
            </v-card-actions>

            <v-card-text>
              
              <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.name')" rules="required|max:100">
                  
                <v-text-field
                    v-model="editedItem.name"
                    :label="$vuetify.lang.t('$vuetify.name')"
                    required="required"
                    :error-messages="errors"
                    counter="true"
                ></v-text-field>

              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.schemaKey')" rules="required|max:50">
                  
                <v-text-field
                    v-model="editedItem.schemaKey"
                    :label="$vuetify.lang.t('$vuetify.schemaKey')"
                    @input="onSchemaKeyChange"
                    required="required"
                    :error-messages="errors"
                    counter="true"
                ></v-text-field>

              </ValidationProvider>
              <v-switch
                    :label="$vuetify.lang.t('$vuetify.enabled')"
                    v-model="editedItem.enabled"
              ></v-switch>
              <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.editingType')" rules="required">
                  
                

                <v-select
                  v-model="editedItem.editingType"
                  :items="editingTypeItems"
                  :label="$vuetify.lang.t('$vuetify.editingType')"
                  dense
                  outlined
                  required="required"
                  :error-messages="errors"
                ></v-select>

              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.schemaType')" rules="required">
                 
                <v-select
                  v-model="editedItem.schemaType"
                  :items="schemaTypeItems"
                  :label="$vuetify.lang.t('$vuetify.schemaType')"
                  dense
                  outlined
                  required="required"
                  :error-messages="errors"
                ></v-select>


              </ValidationProvider>
              <v-subheader
              
            >
              {{ $vuetify.lang.t('$vuetify.configuration') }}
            </v-subheader>
              <v-jsoneditor v-model="editedItem.configurationDecoded" :options="JSONEditorOptions" :plus="true" height="400px" @error="onJSONEditorError"></v-jsoneditor>
              <br>
              <v-subheader
              
            >
              {{ $vuetify.lang.t('$vuetify.configurationLocalization') }}
            </v-subheader>
              <v-jsoneditor v-model="editedItem.configurationLocalizationDecoded" :options="JSONEditorOptions" :plus="true" height="400px" @error="onJSONEditorError"></v-jsoneditor>
              
            </v-card-text>

            <v-card-actions class="pt-8">
              <v-btn text color="secondary" @click="close">
                <v-icon left>mdi-backspace-outline</v-icon>
                {{ $vuetify.lang.t('$vuetify.cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" type="submit">
                <v-icon left>mdi-content-save</v-icon>
                {{ $vuetify.lang.t('$vuetify.save') }}
              </v-btn>
            </v-card-actions>

            
          </form>
        </ValidationObserver>
      </v-container>
    </v-card>
            </v-dialog>
            
          </v-toolbar>
        </template>
        <template v-slot:[`item.schemaToWebSites.showInMenu`]="{ item }">
          <v-icon medium @click="changeShowInMenu(item,!item.schemaToWebSites[0].showInMenu)" v-if="item.schemaToWebSites[0].showInMenu" color="green">mdi-eye-check</v-icon>
          
          
          
          <v-icon medium @click="changeShowInMenu(item,!item.schemaToWebSites[0].showInMenu)" v-if="!item.schemaToWebSites[0].showInMenu" color="red">mdi-eye-off</v-icon>

          
          
        </template>
        <template v-slot:[`item.schemaToWebSites.position`]="{ item }">

         
          
            
          
          
          <v-icon medium v-if="!(item.schemaToWebSites[0].position > 1)" color="red">mdi-cancel</v-icon>
          <v-icon medium @click="moveUpItem(item)" v-if="item.schemaToWebSites[0].position > 1">mdi-arrow-up</v-icon>
          
          
          <v-chip x-small chip>
            {{item.schemaToWebSites[0].position}}
          </v-chip>
          
          <v-icon medium v-if="!(item.schemaToWebSites[0].position < totalEntities)" color="red">mdi-cancel</v-icon>          
          <v-icon medium @click="moveDownItem(item)" v-if="item.schemaToWebSites[0].position < totalEntities ">mdi-arrow-down</v-icon>

          
          
        </template>
        
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </div>
</template>


<script>
import { baseApiUrl,apiCall } from "../utils/api";
import { getFlagFromLangCode } from "../utils/flags";
//import PrettyPrint from "../components/pretty-print";
import _ from 'lodash';
import slugify from 'slugify'
import VJsoneditor from 'v-jsoneditor'

export default {
  name: "Schemas",
  props: {
    schemaKey: {
      type: String,
      default: ""
    }
  },
  components: {
    VJsoneditor
    //"pretty-print": PrettyPrint,    
  },
  data() {
    return {
      tab: null,
      blockModal: false,
      dialog: false,
      totalEntities: 0,
      schema: {},
      entities: [],
      firstLoad: true,
      loading: true,
      form_loaded: false,
      transition: "scale-transition",
      options: {
        page : 1,
        itemsPerPage : 10,
        sortBy: ["e => e.SchemaToWebSites.First().Position"],
         sortDesc: [false]
      },
      editedItem: {},
      defaultItem: {
        languageId: "it",
        resourceKey: "",
        resourceSet:"",
        satus: "TRANSLATED",
        webSiteId: ""
      },  
      canReorder: false,    
      editedIndex: -1,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.name'),
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.schemaKey'),
          align: "start",
          sortable: true,
          value: "schemaKey"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.enabled'),
          align: "start",
          sortable: true,
          value: "enabled"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.editingType'),
          align: "start",
          sortable: true,
          value: "editingType"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.schemaType'),
          align: "start",
          sortable: true,
          value: "schemaType"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.createdAt'),
          align: "start",
          sortable: true,
          value: "createdAt"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.updatedAt'),
          align: "start",
          sortable: true,
          value: "updatedAt"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.position'),
          align: "start",
          sortable: true,
          value: "schemaToWebSites.position"
        },
        {
          text: this.$vuetify.lang.t('$vuetify.showInMenu'),
          align: "start",
          sortable: true,
          value: "schemaToWebSites.showInMenu"
        },
        
        { text: this.$vuetify.lang.t('$vuetify.actions'), value: "actions", sortable: false }
      ],
      state: {},
      isInsert: false,
      search: '',
      editingTypeItems: ["MULTI","SINGLE"],
      schemaTypeItems: ["CONTENT" ,
        "ASSET",
        "CATEGORY",
        "MENU"],
      JSONEditorOptions:{
        mode: 'code',
        modes: ['tree','form', 'code', 'text']
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApiDebounced();
      },
      deep: true,
      immediate: false
    }
  },
  computed: {
    // a computed getter
    schemaConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configuration);
    },
    schemaLocalizationConfiguration: function() {
      // `this` points to the vm instance
      return JSON.parse(this.schema.configurationLocalization);
    },
    /*isInsert: function() {
      return this.editedIndex == -1;
    },*/
    isUpdate: function() {
      return !this.isInsert;// this.editedIndex > -1;
    },
    currentWebSite() {
      return this.$store.getters.getCurrentWebSite;
    }/*,
    canReorder() {
      return this.schemaConfiguration?.dataTable?.canReorder;
    },*/
  },
  mounted() {
    this.getDataFromApiDebounced();
  },
  beforeUpdate() {
    
  },
  methods: {
    onChangeState(value) {
      this.state = value;
    },
    getFlagFromLangCode(langCode)
    {
      return getFlagFromLangCode(langCode);
    },
    getDataFromApi() {
      return new Promise((resolve, reject) => {       

            this.loading = true;
            const {
                  sortBy, sortDesc,
                  page,
                  itemsPerPage
                } = this.options;

            let filters = {
              WebSiteId: this.currentWebSite,
              Page: page,
              Limit: itemsPerPage,
              SortBy: Array.isArray(sortBy) ? sortBy : [...sortBy],
              SortDesc: Array.isArray(sortDesc) ? sortDesc : [...sortDesc],
              GenericSearch: '%'+this.search+'%'
            };
            apiCall({ url: "Schema/paged", data: filters, method: "GET" })
              .then(resp => {
                this.firstLoad = false;
                //console.log("Paged Results Response",resp);
                let items = resp.results;
                const total = resp.rowCount;

                

                //console.log("items", items);

                this.loading = false;
                resolve({
                  items,
                  total
                });
              })
              .catch(err => {
                reject(err);
              });
          
          });
     
    },    
    editItem(item) {
      this.form_loaded = false;
      //console.log(item);
      this.editedIndex = this.entities.indexOf(item);
      this.isInsert = false;

      //console.log("item",item,"this.editedIndex",this.editedIndex,"this.isUpdate",this.isUpdate);

      apiCall({ url: "Schema/" + item.schemaId, data: {}, method: "GET" })
        .then(resp => {
          
          this.entities[this.editedIndex] = resp;

          item = this.entities[this.editedIndex];

          let filters = {
              WebSiteId: this.currentWebSite,
              Page: 0,
              Limit: 1000,
              ResourceKey: item.resourceKey
            };
            apiCall({ url: "Schema", data: filters, method: "GET" })
              .then(respOther => {
                
                item = Object.assign({}, item,{
                  configurationDecoded: JSON.parse(item.configuration) || { "model": {},
                                                                            "schema": {},
                                                                            "uiSchema": []
                                                                          },
                  configurationLocalizationDecoded: JSON.parse(item.configurationLocalization) || { "model": {},
                                                                            "schema": {},
                                                                            "uiSchema": []
                                                                          }
                  });

                

                this.editedItem = item;
                this.form_loaded = true;
                this.dialog = true;
                
              })
              .catch(err => {
                err => {}
              });

          

          
        })
        .catch(err => {});
    },
    newItem() {
      this.form_loaded = false;
      this.editedIndex = -1;
      this.isInsert = true;

      let webSiteId = this.$store.getters.getCurrentWebSite;
      
      this.editedItem = {};
      this.form_loaded = true;
      this.dialog = true;
        
    },
    deleteItem(item) {
      this.$confirm("Cancellare l'elemento corrente?").then(res => {
        //console.log(res);
        if (res) {
          this.blockModal = true;
          apiCall({
            url: "Schema/" + item.schemaId,
            data: {},
            method: "DELETE"
          })
            .then(resp => {
              this.editedItem = -1;

              this.getDataFromApi().then(data => {
                this.entities = data.items;
                this.totalEntities = data.total;
                this.blockModal = false;
              });
            })
            .catch(err => {
              this.blockModal = false;
            });
        }
      });
    },
    moveItem(item,newPosition){
      this.blockModal = true;

      let formData = new FormData();
      //Il nome del campo deve corrispondere al parametro presente nelle api
      formData.append('newPosition', newPosition);
      formData.append('webSiteId', this.currentWebSite);

      apiCall({
            url: "Schema/ChangeWebSiteSchemaPosition/" + item.schemaId,
            data: formData,
            method: "POST"
          })
            .then(resp => {
              this.editedItem = -1;

              this.getDataFromApi().then(data => {
                this.entities = data.items;
                this.totalEntities = data.total;
                this.blockModal = false;
              });
            })
            .catch(err => {
              this.blockModal = false;
            });
    },
    moveUpItem(item){
      this.moveItem(item,item.schemaToWebSites[0].position - 1);
    },
    moveDownItem(item){
      this.moveItem(item,item.schemaToWebSites[0].position + 1);
    },
    changeShowInMenu(item,showInMenu){
      this.blockModal = true;

      let formData = new FormData();
      //Il nome del campo deve corrispondere al parametro presente nelle api
      formData.append('showInMenu', showInMenu);
      formData.append('webSiteId', this.currentWebSite);

      apiCall({
            url: "Schema/ChangeWebSiteSchemaShowInMenu/" + item.schemaId,
            data: formData,
            method: "POST"
          })
            .then(resp => {
              this.editedItem = -1;

              this.getDataFromApi().then(data => {
                this.entities = data.items;
                this.totalEntities = data.total;
                this.blockModal = false;
              });
            })
            .catch(err => {
              this.blockModal = false;
            });
    },
    onDialogClosed() {
      this.dialog = false;
      this.blockModal = false;
      this.$nextTick(() => {
        //this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    onSaved() {
      //console.log(this.editedItem);
      
      this.getDataFromApi().then(data => {
        this.entities = data.items;
        this.totalEntities = data.total;
        this.onDialogClosed();
        this.blockModal = false;
      });
    },
    onSchemaKeyChange (value,key="") {     
      this.editedItem.schemaKey = slugify(value).toLowerCase();
    },
    getDataFromApiDebounced: _.debounce(function() {
          this.getDataFromApi().then(data => {
            this.entities = data.items;
            this.totalEntities = data.total;
          }).catch(e => {
            console.error(e);
          });   
      }, 1000),
    close() {
      this.dialog = false;
    },
    onJSONEditorError() {
            console.error('error')
        },
    save() {
      //console.log("editedItem", this.editedItem, "IsInsert", this.isInsert);
      this.blockModal = true;

      this.defaultItem.webSiteId = this.currentWebSite;

      if(this.isInsert)
      {
        this.editedItem.SchemaToWebSites = [{
          webSiteId : this.currentWebSite
        }];
      }

      this.editedItem.configuration = JSON.stringify(this.editedItem.configurationDecoded,null,0);
      this.editedItem.configurationLocalization = JSON.stringify(this.editedItem.configurationLocalizationDecoded,null,0);

      console.log("this.editedItem",this.editedItem);
      apiCall({
        url: "Schema" + (this.isInsert ? "" : "/" + this.editedItem.schemaId),
        data: Object.assign({}, this.defaultItem,this.editedItem),
        method: this.isInsert ? "POST" : "PUT",
      })
        .then((resp) => {
          this.getDataFromApi().then(data => {
            this.entities = data.items;
            this.totalEntities = data.total;
            this.onDialogClosed();
          });         
          
        })
        .catch((err) => {
          this.blockModal = false;
        });
    }
  }
};
</script>