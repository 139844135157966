var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{attrs:{"loading":_vm.firstLoad,"transition":_vm.transition,"tile":true,"type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.totalEntities,"loading":_vm.loading,"search":_vm.search,"item-key":"id","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.languageId",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"label":"","small":""}},[_c('country-flag',{attrs:{"country":_vm.getFlagFromLangCode(item.languageId),"size":"small"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.languageId))])],1)]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.schema.name))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"change":_vm.getDataFromApiDebounced,"input":_vm.getDataFromApiDebounced},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v("You can use % as a wildcard")])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.newItem}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.addNew')))],1),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","retain-focus":false},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.isInsert ? _vm.$vuetify.lang.t('$vuetify.newItem') : _vm.$vuetify.lang.t('$vuetify.editItem')))])],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-container',[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [(_vm.form_loaded)?_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-backspace-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel'))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.save'))+" ")],1)],1),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.name'),"rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.name'),"required":"required","error-messages":errors,"counter":"true"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.schemaKey'),"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.schemaKey'),"required":"required","error-messages":errors,"counter":"true"},on:{"input":_vm.onSchemaKeyChange},model:{value:(_vm.editedItem.schemaKey),callback:function ($$v) {_vm.$set(_vm.editedItem, "schemaKey", $$v)},expression:"editedItem.schemaKey"}})]}}],null,true)}),_c('v-switch',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.enabled')},model:{value:(_vm.editedItem.enabled),callback:function ($$v) {_vm.$set(_vm.editedItem, "enabled", $$v)},expression:"editedItem.enabled"}}),_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.editingType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.editingTypeItems,"label":_vm.$vuetify.lang.t('$vuetify.editingType'),"dense":"","outlined":"","required":"required","error-messages":errors},model:{value:(_vm.editedItem.editingType),callback:function ($$v) {_vm.$set(_vm.editedItem, "editingType", $$v)},expression:"editedItem.editingType"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$vuetify.lang.t('$vuetify.schemaType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.schemaTypeItems,"label":_vm.$vuetify.lang.t('$vuetify.schemaType'),"dense":"","outlined":"","required":"required","error-messages":errors},model:{value:(_vm.editedItem.schemaType),callback:function ($$v) {_vm.$set(_vm.editedItem, "schemaType", $$v)},expression:"editedItem.schemaType"}})]}}],null,true)}),_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.configuration'))+" ")]),_c('v-jsoneditor',{attrs:{"options":_vm.JSONEditorOptions,"plus":true,"height":"400px"},on:{"error":_vm.onJSONEditorError},model:{value:(_vm.editedItem.configurationDecoded),callback:function ($$v) {_vm.$set(_vm.editedItem, "configurationDecoded", $$v)},expression:"editedItem.configurationDecoded"}}),_c('br'),_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.configurationLocalization'))+" ")]),_c('v-jsoneditor',{attrs:{"options":_vm.JSONEditorOptions,"plus":true,"height":"400px"},on:{"error":_vm.onJSONEditorError},model:{value:(_vm.editedItem.configurationLocalizationDecoded),callback:function ($$v) {_vm.$set(_vm.editedItem, "configurationLocalizationDecoded", $$v)},expression:"editedItem.configurationLocalizationDecoded"}})],1),_c('v-card-actions',{staticClass:"pt-8"},[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-backspace-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.cancel'))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary","type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.save'))+" ")],1)],1)],1):_vm._e()]}}])})],1)],1)],1)],1)]},proxy:true},{key:"item.schemaToWebSites.showInMenu",fn:function(ref){
var item = ref.item;
return [(item.schemaToWebSites[0].showInMenu)?_c('v-icon',{attrs:{"medium":"","color":"green"},on:{"click":function($event){return _vm.changeShowInMenu(item,!item.schemaToWebSites[0].showInMenu)}}},[_vm._v("mdi-eye-check")]):_vm._e(),(!item.schemaToWebSites[0].showInMenu)?_c('v-icon',{attrs:{"medium":"","color":"red"},on:{"click":function($event){return _vm.changeShowInMenu(item,!item.schemaToWebSites[0].showInMenu)}}},[_vm._v("mdi-eye-off")]):_vm._e()]}},{key:"item.schemaToWebSites.position",fn:function(ref){
var item = ref.item;
return [(!(item.schemaToWebSites[0].position > 1))?_c('v-icon',{attrs:{"medium":"","color":"red"}},[_vm._v("mdi-cancel")]):_vm._e(),(item.schemaToWebSites[0].position > 1)?_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.moveUpItem(item)}}},[_vm._v("mdi-arrow-up")]):_vm._e(),_c('v-chip',{attrs:{"x-small":"","chip":""}},[_vm._v(" "+_vm._s(item.schemaToWebSites[0].position)+" ")]),(!(item.schemaToWebSites[0].position < _vm.totalEntities))?_c('v-icon',{attrs:{"medium":"","color":"red"}},[_vm._v("mdi-cancel")]):_vm._e(),(item.schemaToWebSites[0].position < _vm.totalEntities )?_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.moveDownItem(item)}}},[_vm._v("mdi-arrow-down")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }